/* eslint-disable @typescript-eslint/no-useless-constructor */
import type { PromptConfig } from './PromptConfig';
import type { SupportedBiometricType } from './definitions';
import { BiometricPermissionState, BiometricSecurityStrength } from './definitions';

export class Device {
  /** @ignore */
  constructor() {
    // Nothing
  }

  /**
   * Gets the types of biometrics the device supports.
   *
   * @usage
   * ```typescript
   * const hardware = await Device.getAvailableHardware();
   * hardware.forEach((biometricType) => console.log("Type: " + biometricType));
   * ```
   */
  public static getAvailableHardware(): Promise<SupportedBiometricType[]> {
    return new Promise<SupportedBiometricType[]>((resolve, reject) => {
      if (!('cordova' in window)) {
        resolve([]);
        return;
      }

      cordova.exec(
        (data) => {
          resolve(data);
        },
        (error) => {
          Device.handleError(resolve, reject, error);
        },
        'DevicePlugin',
        'getAvailableHardware',
        []
      );
    });
  }

  /**
   * Check whether biometrics are locked out on the device.
   *
   * On Android, the locked out state will only known after an attempted biometric unlock.
   *
   * @usage
   * ```typescript
   * const isLockedOut = await Device.isLockedOutOfBiometrics();
   * if (isLockedOut) {
   *  // device is locked out ...
   * }
   * ```
   */
  public static isLockedOutOfBiometrics(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!('cordova' in window)) {
        resolve(false);
        return;
      }

      cordova.exec(
        (data) => {
          resolve(JSON.parse(data));
        },
        (error) => {
          Device.handleError(resolve, reject, error);
        },
        'DevicePlugin',
        'isLockedOutOfBiometrics',
        []
      );
    });
  }

  /**
   * Checks the device biometric strength level.
   *
   * On iOS this will always return 'strong'.
   *
   * @usage
   * ```typescript
   * const biometricStrength = await Device.getBiometricStrengthLevel();
   * ```
   */
  public static getBiometricStrengthLevel(): Promise<BiometricSecurityStrength> {
    return new Promise<BiometricSecurityStrength>((resolve, reject) => {
      if (!('cordova' in window)) {
        resolve(BiometricSecurityStrength.Weak);
        return;
      }
      cordova.exec(
        (data) => {
          resolve(data);
        },
        (error) => {
          Device.handleError(resolve, reject, error);
        },
        'DevicePlugin',
        'getBiometricStrengthLevel',
        []
      );
    });
  }

  /**
   * Check whether the device OS-level passcode has been set.
   *
   * @usage
   * ```typescript
   * const hasSystemPasscode = await Device.isSystemPasscodeSet();
   * if (hasSystemPasscode) {
   *  // device has a system passcode
   * }
   * ```
   */
  public static isSystemPasscodeSet(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!('cordova' in window)) {
        resolve(false);
        return;
      }
      cordova.exec(
        (data) => {
          resolve(JSON.parse(data));
        },
        (error) => {
          Device.handleError(resolve, reject, error);
        },
        'DevicePlugin',
        'isSystemPasscodeSet',
        []
      );
    });
  }

  /**
   * Check whether or not biometrics is supported by the device and has been configured by the current user of the device.
   *
   * @usage
   * ```typescript
   * const biometricsEnabled = await Device.isBiometricsEnabled();
   * if (!biometricsEnabled) {
   *  // biometrics not enabled on this device...
   * }
   * ```
   */
  public static isBiometricsEnabled(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!('cordova' in window)) {
        resolve(false);
        return;
      }

      cordova.exec(
        (data) => {
          console.log('biometrics is enabled: ', data);
          resolve(JSON.parse(data));
        },
        (error) => {
          Device.handleError(resolve, reject, error);
        },
        'DevicePlugin',
        'isBiometricsEnabled',
        []
      );
    });
  }

  /**
   * Returns the current state of biometric permissions.
   *
   * On Android and iOS devices with TouchID, this always returns `granted`.
   *
   * @usage
   * ```typescript
   * const permissions = await Device.isBiometricsAllowed();
   * if (permissions === BiometricPermissionState.Denied) {
   *  // user must enable FaceID for the application...
   * }
   * ```
   */
  public static isBiometricsAllowed(): Promise<BiometricPermissionState> {
    return new Promise<BiometricPermissionState>((resolve, reject) => {
      if (!('cordova' in window)) {
        resolve(BiometricPermissionState.Denied);
        return;
      }

      cordova.exec(
        (data) => {
          resolve(data);
        },
        (error) => {
          Device.handleError(resolve, reject, error);
        },
        'DevicePlugin',
        'isBiometricsAllowed',
        []
      );
    });
  }

  /**
   * Check whether or not biometrics is supported by the device.
   *
   * @usage
   * ```typescript
   * const biometricsSupported = await Device.isBiometricsSupported();
   * if (biometricsSupported) {
   *   // biometrics is supported on this device...
   * }
   * ```
   */
  public static isBiometricsSupported(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!('cordova' in window)) {
        resolve(false);
        return;
      }

      cordova.exec(
        (data) => {
          resolve(JSON.parse(data));
        },
        (error) => {
          Device.handleError(resolve, reject, error);
        },
        'DevicePlugin',
        'isBiometricsSupported',
        []
      );
    });
  }

  /**
   * Check if the device has a secure hardware enclave.
   *
   * @usage
   * ```typescript
   * const hasSecureHardware = await Device.hasSecureHardware();
   * if (!hasSecureHardware) {
   *    // this device doesn't have secure hardware
   * }
   * ```
   */
  public static hasSecureHardware(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!('cordova' in window)) {
        resolve(false);
        return;
      }

      cordova.exec(
        (data) => {
          resolve(JSON.parse(data));
        },
        (error) => {
          Device.handleError(resolve, reject, error);
        },
        'DevicePlugin',
        'hasSecureHardware',
        []
      );
    });
  }

  /**
   * Show a biometric prompt.
   *
   * @usage
   * ```typescript
   * try {
   *  const promptConfig = {...};
   *  await Device.showBiometricPrompt(promptConfig);
   *  // biometric prompt succeeded successfully
   * } catch (err) {
   *  // handle error
   * }
   * ```
   */
  public static showBiometricPrompt(config: PromptConfig): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!('cordova' in window)) {
        resolve();
        return;
      }

      cordova.exec(
        () => {
          resolve();
        },
        (error) => {
          Device.handleError(resolve, reject, error);
        },
        'DevicePlugin',
        'showBiometricPrompt',
        [config]
      );
    });
  }

  /**
   * @ignore
   */
  private static async handleError(
    resolve: (data: any) => void,
    reject: (error: any) => void,
    error: any,
    retryFunc?: any
  ) {
    reject(error);
  }
}
