import type { IdentityVaultConfig } from './IdentityVaultConfig';
import type { VaultInterface, VaultLockEvent } from './VaultInterface';
import type { VaultError } from './definitions';
import { AndroidBiometricCryptoPreference, DeviceSecurityType, VaultErrorCodes, VaultType } from './definitions';

/**
 * Represents a vault for secure value storage
 */
export class Vault implements VaultInterface {
  /** @ignore */
  private passCodeRequestedCallback?:
    | ((isPasscodeSetRequest: boolean, onComplete: (code: string) => void) => void)
    | ((isPasscodeSetRequest: boolean) => Promise<void>);

  /** @ignore */
  private defaultConfig = {
    deviceSecurityType: DeviceSecurityType.None,
    androidBiometricsPreferStrongVaultOrSystemPasscode: AndroidBiometricCryptoPreference.StrongVault,
    shouldClearVaultAfterTooManyFailedAttempts: false,
    customPasscodeInvalidUnlockAttempts: 5,
    unlockVaultOnLoad: false,
  };

  /**
   * Contains the current config properties of the vault.
   * See the {@link IdentityVaultConfig | IdentityVaultConfig} docs page for more info.
   *
   * @usage
   * ```typescript
   * const newVault = new Vault();
   * await newVault.initialize(vaultConfig);
   * if(newVault.config.deviceSecurityType === DeviceSecurityType.None) {
   *   ...
   * }
   * ```
   *
   */
  config: IdentityVaultConfig | undefined;

  /**
   * @usage
   * ```typescript
   * const vault = new Vault();
   *
   * Call initialize after create a vault instead of passing in the configuration in the constructor.
   * ```
   * @param config? Deprecated (use initialize instead)
   */
  constructor(config?: IdentityVaultConfig) {
    // This allows the vault creation to be done by initialize instead
    if (!config) {
      return;
    }
    // set defaults
    this.config = Object.assign(this.defaultConfig, config);

    // setting sane defaults
    if (config.deviceSecurityType === DeviceSecurityType.None && config.type === VaultType.DeviceSecurity) {
      config.deviceSecurityType = DeviceSecurityType.Both;
    }

    this.resume = this.resume.bind(this);
    this.handleError = this.handleError.bind(this);
    this.setup().then(() => {
      this.getPersistedVaultConfig();
    });
  }

  /**
   * @usage
   * ```typescript
   * await vault.initialize({
   *  key: 'com.company.myvaultapp',
   *  type: 'CustomPasscode',
   *  deviceSecurityType: DeviceSecurityType.None,
   *  lockAfterBackgrounded: 2000,
   * });
   * ```
   * @param config The default configuration that is used when initializing a new vault. If a vault already exists identified by the specified key, then the configuration stored with that vault shall be read and used instead.
   */
  initialize(config: IdentityVaultConfig): Promise<void> {
    // set defaults
    this.config = Object.assign(this.defaultConfig, config);

    // setting sane defaults
    if (config.deviceSecurityType === DeviceSecurityType.None && config.type === VaultType.DeviceSecurity) {
      config.deviceSecurityType = DeviceSecurityType.Both;
    }

    this.resume = this.resume.bind(this);
    this.handleError = this.handleError.bind(this);
    return new Promise<void>((resolve, reject) => {
      this.setup()
        .then(() => {
          this.getPersistedVaultConfig()
            .then(() => {
              resolve();
            })
            .catch((e) => {
              reject(e);
            });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  /**
   * Resolves true if a vault with the same key already exists, and false if not.
   * The vault does not need to be unlocked to check.
   *
   * __Note:__
   *  - Using {@link Vault.removeValue | removeValue()} to remove all of your vault data will not cause this function to resolve false, however {@link Vault.clear | clear()} will.
   *  - A vault only exists once it has been interacted with at least once via any of the following instance methods.
   *    - {@link Vault.setValue | setValue()}
   *    - {@link Vault.removeValue | removeValue()}
   *    - {@link Vault.importVault | importVault()}
   *    - {@link Vault.exportVault | exportVault()}
   *    - {@link Vault.unlock | unlock()}
   *    - {@link Vault.updateConfig | updateConfig()}
   *
   * @usage
   * ```typescript
   * const vault = new Vault();
   * await vault.initialize(existingVaultConfig);
   * const vaultExists = await vault.doesVaultExists()
   * if (!vaultExists) {
   *  // the vault does not exist...
   * }
   * ```
   *
   * @deprecated Deprecated in favor of using the {@link Vault.isEmpty | isEmpty()} method.
   */
  doesVaultExist(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      cordova.exec(
        (data) => {
          resolve(JSON.parse(data));
        },
        (error) => {
          this.handleError(resolve, reject, error, () => this.doesVaultExist());
        },
        'VaultPlugin',
        'doesVaultExist',
        [this.config]
      );
    });
  }

  /**
   * Clears out the current vault and removes it from the system.
   * Note: The vault does not need to be unlocked in order to clear it. No credentials are checked
   * when clearing the vault.
   *
   * @usage
   * ```typescript
   * const vault = new Vault();
   * await vault.initialize(existingVaultConfig);
   * await vault.clear();
   * ```
   */
  clear(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      cordova.exec(
        (data) => {
          resolve();
        },
        (error) => {
          this.handleError(resolve, reject, error, () => this.clear());
        },
        'VaultPlugin',
        'clear',
        [this.config]
      );
    });
  }

  /**
   * Exports the data of the current vault in its entirety.
   * The data is a map with keys that are strings and values that are JSON.
   * Calling `exportVault` will attempt to unlock the vault if it is currently locked.
   *
   * @usage
   * ```typescript
   * const vault = new Vault();
   * await vault.initialize(existingVaultConfig);
   * const data = await vault.exportVault();
   * ```
   *
   * @return The resolved object is a map with string keys and string values.
   *
   */
  exportVault(): Promise<{ [key: string]: string }> {
    return new Promise<{ [key: string]: string }>((resolve, reject) => {
      cordova.exec(
        (data) => {
          resolve(JSON.parse(data));
        },
        (error) => {
          this.handleError(resolve, reject, error, () => this.exportVault());
        },
        'VaultPlugin',
        'exportVault',
        [this.config]
      );
    });
  }

  /**
   * Imports data into the vault, replacing the current contents of the vault.
   * The data is a map with keys that are strings and values that are JSON.
   * Calling `importVault` will attempt to unlock the vault if it is currently locked.
   *
   * @usage
   * ```typescript
   * const dataFromElsewhere = await getUserData();
   * const newVault = new Vault();
   * await newVault.initialize(vaultConfig);
   * await newVault.importVault(dataFromElsewhere);
   * ```
   *
   * @param data The entire data object to be imported. The shape of data must be {[key: string]: string}.
   *
   */
  importVault(data: { [key: string]: string }): Promise<void> {
    const jsonData = JSON.stringify(data);
    return new Promise<void>((resolve, reject) => {
      cordova.exec(
        resolve,
        (error) => {
          this.handleError(resolve, reject, error, () => this.importVault(data));
        },
        'VaultPlugin',
        'importVault',
        [this.config, jsonData]
      );
    });
  }

  /**
   * Checks if the vault is currently in a locked state, which signifies that the contents
   * of the secure vault are not currently accessible. `isLocked` can also return true if the
   * vault does not exist.
   *
   * If a vault has a `VaultType` of `SecureStorage` or `InMemory` this method will return `false` as these vault types cannot be locked.
   *
   * @usage
   * ```typescript
   * const vault = new Vault();
   * await vault.initialize(existingVaultConfig);
   * const locked = await vault.isLocked();
   * if (locked) {
   *  // vault is locked (or does not exist);
   * }
   * ```
   */
  isLocked(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      cordova.exec(
        (data) => {
          resolve(JSON.parse(data));
        },
        (error) => {
          this.handleError(resolve, reject, error);
        },
        'VaultPlugin',
        'isLocked',
        [this.config]
      );
    });
  }

  /**
   * Returns an array of keys that are currently in the vault.
   * Calling `getKeys` will attempt to unlock the vault if it is currently locked.
   *
   * @usage
   * ```typescript
   * const vault = new Vault();
   * await vault.initialize(existingVaultConfig);
   * const allKeys = await vault.getKeys();
   * allKeys.forEach((key) => {
   *  // do something with the key
   * });
   * ```
   */
  getKeys(): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      cordova.exec(
        (data) => {
          resolve(JSON.parse(data));
        },
        (error) => {
          this.handleError(resolve, reject, error, () => this.getKeys());
        },
        'VaultPlugin',
        'getKeys',
        [this.config]
      );
    });
  }

  /**
   * Gets the value for a given key. Returns null if the key does not exist.
   * Calling `getValue` will attempt to unlock the vault if it is currently locked.
   *
   * @usage
   * ```typescript
   * const vault = new Vault();
   * await vault.initialize(existingVaultConfig);
   * const userFirstName = await vault.getValue<string>("firstname");
   * ```
   *
   * @param key The key to look up the value for
   *
   */
  getValue<T = any>(key: string): Promise<T | null> {
    return new Promise<T | null>((resolve, reject) => {
      cordova.exec(
        (data: string | null) => {
          if (!data) {
            // android returns null as an empty string, so manually convert it here
            resolve(null);
          } else {
            try {
              resolve(JSON.parse(data));
            } catch (err) {
              resolve(data as any);
            }
          }
        },
        (error) => {
          this.handleError(resolve, reject, error, () => this.getValue(key));
        },
        'VaultPlugin',
        'getValue',
        [this.config, key]
      );
    });
  }

  /**
   * Locks the vault if it is currently unlocked.
   * Locking the vault with remove all secure data from memory inside of Identity Vault, but not your application.
   *
   * For Vaults of type `SecureStorage` or `InMemory` this method will have no effect on the vault.
   *
   * @usage
   * ```typescript
   * const vault = new Vault();
   * await vault.initialize(existingVaultConfig);
   * await vault.lock();
   * ```
   */
  lock(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      cordova.exec(
        (data) => {
          resolve();
        },
        (error) => {
          this.handleError(resolve, reject, error);
        },
        'VaultPlugin',
        'lock',
        [this.config]
      );
    });
  }

  /**
   * Removes a value from the vault.
   * Calling `removeValue` will attempt to unlock the vault if it is currently locked.
   *
   * @usage
   * ```typescript
   * const vault = new Vault();
   * await vault.initialize(existingVaultConfig);
   * await vault.removeValue("address");
   * ```
   *
   * @param key The key to remove
   *
   */
  removeValue(key: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      cordova.exec(
        resolve,
        (error) => {
          this.handleError(resolve, reject, error, () => this.removeValue(key));
        },
        'VaultPlugin',
        'removeValue',
        [this.config, key]
      );
    });
  }

  /**
   * When the vault type is set to 'CustomPasscode', this method sets the passcode required to
   * secure the vault. If the vault is unlocked this method can be used to change the passcode.
   *
   * This method is typically called in the `onPasscodeRequested` callback.
   *
   * @usage
   * ```typescript
   * const vault = new Vault();
   * await vault.initialize(existingVaultConfig);
   * const code = window.prompt("Enter your passcode.");
   * if (code) {
   *  await vault.setCustomPasscode(code);
   * }
   * ```
   *
   * @param passcode The user supplied passcode to secure the vault with.
   *
   */
  setCustomPasscode(passcode: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      cordova.exec(
        resolve,
        (error) => {
          this.handleError(resolve, reject, error);
        },
        'VaultPlugin',
        'setCustomPasscode',
        [this.config, passcode]
      );
    });
  }

  /**
   * Sets the value of an item in the vault.
   * Calling `setValue` will attempt to unlock the vault if it is currently locked.
   *
   * @usage
   * ```typescript
   * const vault = new Vault();
   * await vault.initialize(existingVaultConfig);
   * await vault.setValue<string>("theme", theme);
   * ```
   *
   * @param key The key for the new value.
   * @param value The value to store in the vault. Value can be of any type, as it will be parsed to JSON in the vault.
   *
   */
  setValue<T = any>(key: string, value: T): Promise<void> {
    const jsonValue = JSON.stringify(value);
    return new Promise<void>((resolve, reject) => {
      cordova.exec(
        resolve,
        (error) => {
          this.handleError(resolve, reject, error, () => this.setValue(key, value));
        },
        'VaultPlugin',
        'setValue',
        [this.config, key, jsonValue]
      );
    });
  }

  /**
   * Triggers when a config change occurs.
   *
   * @usage
   * ```typescript
   * vault.onConfigChanged((config) => {
   *  console.log("updated config: ", config);
   * });
   * ```
   * @param callback The callback function that will be called when the event triggers. Passes in the current vault config.
   *
   */
  onConfigChanged(callback: (config: IdentityVaultConfig) => void): void {
    cordova.exec(
      (data) => {
        callback(JSON.parse(data));
      },
      (error) => console.error(error),
      'VaultPlugin',
      'onConfigChanged',
      []
    );
  }

  /**
   * Triggers when an error occurs in the application.
   * Errors that come back as rejected promises also trigger this event.
   *
   * @usage
   * ```typescript
   * vault.onError((err) => {
   *  console.log('ERROR from callback', JSON.stringify(err));
   * });
   * ```
   *
   * @param callback The callback function that will be called when the event triggers. Passes in the error object.
   *
   */
  onError(callback: (err: VaultError) => void): void {
    cordova.exec(
      () => {
        // Do Nothing
      },
      async (error: VaultError) => {
        if (!(error.code === VaultErrorCodes.MissingPasscode && !(await this.isEmpty()))) {
          callback(error);
        }
      },
      'VaultPlugin',
      'onError',
      [this.config]
    );
  }

  /**
   * Triggers when the vault enters a locked state.
   *
   * @usage
   * ```typescript
   * vault.onLock((lockEvent) => { displayNotification(`Vault locked. Was from timeout: ${lockEvent.timeout}`); })
   * ```
   *
   * @param callback The callback function that will be called when the event triggers. Passes in an object with a boolean property of `timeout` indicating if the lock was due to a background timeout or not.
   *
   */
  onLock(callback: (lockEvent: VaultLockEvent) => void): void {
    cordova.exec(
      (data) => {
        const d = JSON.parse(data);
        callback({ timeout: d.timeout });
      },
      (error) => console.error(error),
      'VaultPlugin',
      'onLock',
      [this.config]
    );
  }

  /**
   * For CustomPasscode vaults, this event triggers when the vault is attempting to unlock and needs the user to provide a passcode.
   *
   * The callback parameter is a function that has two parameters available to it:
   * - `isPasscodeSetRequest` is a boolean value indicating whether the passcode needs to be setup for the first time or not.
   * - `onComplete` is a function that accepts a string parameter 'code', that when called will set the passcode on the vault and attempt to unlock the vault again calling the same method that originally tried to unlock the vault.
   *
   * @usage
   * ```typescript
   * vault.onPasscodeRequested((isPasscodeSetRequest, onComplete) => {
   *   const message = isPasscodeSetRequest
   *     ? 'Setup Passcode' // passcode is being set for first time
   *     : 'Enter passcode'; // passcode is being asked for unlock
   *   const passcode = window.prompt(message) || '';
   *   onComplete(passcode);
   * });
   * ```
   *
   * @param callback The callback function that will be called when the event triggers. Contains a boolean that indicates if the passcode is being setup for the first time for the vault or not, and an onComplete function to be called when a passcode is ready to be set on the vault.
   *
   */
  onPasscodeRequested(callback: (isPasscodeSetRequest: boolean, onComplete: (code: string) => void) => void): void;
  /**
   * For CustomPasscode vaults, this event triggers when the vault is attempting to unlock and needs the user to provide a passcode.
   *
   * The callback parameter is a async function that has one parameter available to it:
   * - `isPasscodeSetRequest` is a boolean value indicating whether the passcode needs to be setup for the first time or not.
   *
   * When the callback function is resolved, an attempt to unlock the vault again calling the same method that originally tried to unlock the vault will be made.
   * Before the function is resolved, you should prompt the user to supply a passcode, and then supply that value to `setCustomPasscode`.
   *
   * @usage
   * ```typescript
   * vault.onPasscodeRequested(async (isPasscodeSetRequest) => {
   *   const message = isPasscodeSetRequest
   *     ? 'Setup Passcode' // passcode is being set for first time
   *     : 'Enter passcode'; // passcode is being asked for unlock
   *   // async yourGetPasscodeFromUser() returns a string of the users entry or null if canceled.
   *   const passcode = await yourGetPasscodeFromUser(message);
   *   await vault.setCustomPasscode(passcode ?? '');
   * });
   * ```
   *
   * @param callback The callback function that will be called when the event triggers. This async function returns a promise with a boolean that indicates if the passcode is being setup for the first time for the vault or not.
   *
   */
  onPasscodeRequested(callback: (isPasscodeSetRequest: boolean) => Promise<void>): void;
  onPasscodeRequested(callback: (isPasscodeSetRequest: boolean, onComplete: (code: string) => void) => void): void {
    this.passCodeRequestedCallback = callback;
  }

  /**
   * Triggers when the vault enters an unlocked state.
   *
   * @usage
   * ```typescript
   * vault.onUnlock(() => {
   *  console.log("vault is now unlocked");
   * });
   * ```
   * @param callback The callback function that will be called when the event triggers.
   *
   */
  onUnlock(callback: () => void): void {
    cordova.exec(callback, (error) => console.error(error), 'VaultPlugin', 'onUnlock', [this.config]);
  }

  /**
   * Manually unlock the vault. Will trigger any authentication mechanism needed to access the vault (passcode, biometrics, etc..).
   *
   * For Vaults of type `SecureStorage` or `InMemory` this method will have no effect on the vault.
   *
   * @usage
   * ```typescript
   * const vault = new Vault();
   * await vault.initialize(existingVaultConfig);
   * await vault.unlock();
   * ```
   */
  unlock(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      cordova.exec(
        () => {
          resolve();
        },
        (error) => {
          this.handleError(resolve, reject, error, () => this.unlock());
        },
        'VaultPlugin',
        'unlock',
        [this.config]
      );
    });
  }

  /**
   * Updates the configuration of the current vault.
   *
   *  @usage
   * ```typescript
   * async function changeVaultType(type: VaultType) {
   *  const vault = new Vault();
   *  await vault.initialize(this.existingVaultConfig);
   *  const newConfig = { ...this.existingVaultConfig, type };
   *  await vault.updateConfig(newConfig);
   *  this.existingVaultConfig = newConfig;
   * }
   * ```
   *
   * @param config The new config
   *
   */
  updateConfig(config: IdentityVaultConfig): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      config = Object.assign(this.defaultConfig, config);

      // setting sane defaults
      if (config.deviceSecurityType === DeviceSecurityType.None && config.type === VaultType.DeviceSecurity) {
        config.deviceSecurityType = DeviceSecurityType.Both;
      }

      cordova.exec(
        () => {
          this.config = config;
          resolve();
        },
        (error) => {
          this.handleError(resolve, reject, error, () => this.updateConfig(config));
        },
        'VaultPlugin',
        'updateConfig',
        [config]
      );
    });
  }

  /**
   * Resolves true if a vault contains no data, and false if any data exists in the vault.
   * The vault does not need to be unlocked to check.
   *
   * __Note:__ Vaults created prior to version 5.2.0 will return false until the vault is unlocked for the first time after updating, even if the vault contains no data.
   * After which this method will return the expected value.
   *
   * @usage
   * ```typescript
   * const vault = new Vault();
   * await vault.initialize(existingVaultConfig);
   * const vaultIsEmpty = await vault.isEmpty()
   * if (vaultIsEmpty) {
   *  // the vault is empty and contains no data...
   * }
   * ```
   */
  isEmpty(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      cordova.exec(
        (data) => {
          resolve(JSON.parse(data));
        },
        (error) => {
          this.handleError(resolve, reject, error);
        },
        'VaultPlugin',
        'isEmpty',
        [this.config]
      );
    });
  }

  /** @ignore */
  private async handleError(resolve: (data: any) => void, reject: (error: any) => void, error: any, retryFunc?: any) {
    if (error.code === VaultErrorCodes.MissingPasscode && this.passCodeRequestedCallback) {
      const checkRetry = async () => {
        if (retryFunc) {
          try {
            const data = await retryFunc();
            resolve(data);
          } catch (e) {
            reject(e);
          }
        }
      };

      const res = this.passCodeRequestedCallback(error.extra.isPasscodeSetRequest, (code: string) => {
        this.setCustomPasscode(code);
        checkRetry();
      });

      if (res instanceof Promise) {
        await res;
        await checkRetry();
      }
    } else if (error.code === VaultErrorCodes.MissingBiometrics) {
      cordova.exec(
        async () => {
          try {
            const data = await retryFunc();
            resolve(data);
          } catch (e) {
            reject(e);
          }
        },
        (e) => reject(e),
        'VaultPlugin',
        'requestBiometricPrompt',
        [this.config]
      );
    } else {
      reject(error);
    }
  }

  /** @ignore */
  requestBiometricPrompt(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      cordova.exec(
        (data) => {
          resolve(JSON.parse(data));
        },
        (error) => {
          this.handleError(resolve, reject, error, () => this.requestBiometricPrompt());
        },
        'VaultPlugin',
        'requestBiometricPrompt',
        [this.config]
      );
    });
  }

  /**
   * @ignore
   */
  private resume() {
    const noop = () => {
      // Do Nothing
    };
    cordova.exec(
      noop,
      (error) => {
        this.handleError(noop, noop, error, () => this.resume());
      },
      'VaultPlugin',
      'appResumed',
      [this.config]
    );
  }

  /**
   * @ignore
   */
  private setup() {
    return new Promise((resolve, reject) => {
      document.addEventListener('resume', this.resume, false);
      cordova.exec(
        resolve,
        (error) => {
          this.handleError(resolve, reject, error);
        },
        'VaultPlugin',
        'setup',
        [this.config]
      );
    });
  }

  /**
   * @ignore
   */
  private getPersistedVaultConfig() {
    return new Promise<void>((resolve, reject) => {
      cordova.exec(
        (data: string | null) => {
          if (!data) {
            resolve();
          } else {
            const vaultConfig = JSON.parse(data) as IdentityVaultConfig;
            this.config = Object.assign(this.config ? this.config : {}, vaultConfig);
            resolve();
          }
        },
        (error) => {
          this.handleError(resolve, reject, error);
        },
        'VaultPlugin',
        'getVaultConfig',
        [this.config]
      );
    });
  }
}
