/**
 * The meaning of the error code in the thrown {@link VaultError}.
 */
export enum VaultErrorCodes {
  /**
   * An unknown error happened.
   * @constant `0`
   */
  Unknown,
  /**
   * The operation failed because the vault was locked.
   * @constant `1`
   */
  VaultLocked,
  /**
   * The operation failed because the vault was unavailable.
   * The most likely cause of this error is that a vault has not been configured.
   * @constant `2`
   */
  VaultUnavailable,
  /**
   * The operation failed because the some of the vault provided arguments were invalid.
   * @constant `3`
   */
  InvalidArguments,
  /**
   * The credentials were invalidated. This can happen when a user changes biometrics or passcode. For Vaults with the DeviceSecurityType of Both if the biometrics change the vault will be invalidated on iOS but will not be on Android.
   * @constant `4`
   */
  InvalidatedCredential,
  /**
   * Biometric security is unavailable due to a passcode not being set up at the system level.
   * In order to use biometric identification on the device a system level passcode must be set up by the user.
   * @constant `5`
   */
  SecurityNotAvailable,
  /**
   * User authentication failed. This can occur when the user has denied use of biometrics or force closed the app during a biometric prompt.
   * You can call the Device.isBiometricsAllowed method to determine if the user has denied access to Biometrics.
   * @constant `6`
   */
  AuthFailed,
  /**
   * Too many failed authentication attempts made against the custom passcode vault, so the vault was cleared and user will need to login again.
   * @constant `7`
   */
  TooManyFailedAttempts,
  /**
   * The user cancelled the native authentication dialog.
   *
   * **Note**: On iOS, this error code will also be thrown in the event that the native authentication dialog fails as a result of too many failed attempts.
   * The user will be forced to cancel the dialog, triggering this error code.
   *
   * On Android, this error also will be thrown if using {@link DeviceSecurityType.SystemPasscode} or {@link DeviceSecurityType.Both} in the event of too many failed attempts.
   * Its currently not possible to get the nature of failures from the native authentication dialog, and similar to iOS, the user will be forced to cancel the dialog, triggering this error code.
   * @constant `8`
   */
  UserCanceledInteraction,
  /**
   * The user provided mismatched passcodes.
   * @constant `9`
   */
  MismatchedPasscode,
  /**
   * The operation requires passcode to be setup but it isn't set yet. Call {@link Vault.setCustomPasscode} to set it.
   * @constant `10`
   */
  MissingPasscode,
  /**
   * The operation failed because the application tried to unlock the vault with passcode authentication,
   * but the vault is not configured to allow passcode authentication.
   * @constant `11`
   */
  PasscodeNotEnabled,
  /**
   * The key was not found. This can happen when a user changes biometrics or passcode.
   * @constant `12`
   */
  KeyNotFound,
  /**
   * The operation failed because biometric authentication is not enabled.
   * This can occur when biometrics is not supported by the device
   * or when biometrics has not been configured for the device or vault.
   * @constant `13`
   */
  BiometricsNotEnabled,
  /**
   * @ignore
   */
  InvalidAuthMode,
  /**
   * Biometrics have not been authed yet (Android only)
   * @constant `15`
   */
  MissingBiometrics,
  /**
   * {@link DeviceSecurityType.SystemPasscode} for {@link DeviceSecurityType} not available on this Android device.
   * @constant `16`
   */
  AndroidSystemPasscodeUnavailable,
  /**
   * Biometrics have been locked out, usually because of too many failed attempts.
   *
   * **Note:** This error will never be thrown if using {@link DeviceSecurityType.SystemPasscode} or {@link DeviceSecurityType.Both}.
   * Its currently not possible to get the nature of failures from the native authentication dialog, so the only error that would be thrown is {@link VaultErrorCodes.UserCanceledInteraction} as the user
   * is forced to cancel the prompt.
   *
   * @constant `17`
   */
  AndroidBiometricsLockedOut,
  /**
   * Biometrics have been locked out, usually because of too many failed attempts.
   *
   * @constant `18`
   */
  iOSBiometricsLockedOut,
  /**
   * An unexpected error has occurred in the Android keystore.
   * Some android devices (such as the Samsung S10) will return this error code when
   * a maximum number of failed attempts is reached and the device must be restarted.
   * See: [Max Uses Per Boot](https://source.android.com/security/keystore/tags#max_uses_per_boot)
   *
   * @constant `19`
   */
  AndroidUnexpectedKeystoreError,
}

/**
 * The meaning of the error code in the thrown {@link DeviceError}.
 */
export enum DeviceErrorCodes {
  /**
   * An unknown error happened.
   * @constant `0`
   */
  Unknown,
  /**
   * The operation failed because the some of the vault provided arguments were invalid.
   * @constant `1`
   */
  InvalidArguments,
  /**
   * The user cancelled the native authentication dialog.
   *
   * **Note**: On iOS, this error code will also be thrown in the event that the native authentication dialog fails as a result of too many failed attempts.
   * The user will be forced to cancel the dialog, triggering this error code.
   *
   * @constant `2`
   */
  UserCanceledInteraction,
  /**
   * Biometric security is unavailable due to a passcode not being set up at the system level.
   * In order to use biometric identification on the device a system level passcode must be set up by the user.
   * @constant `3`
   */
  SecurityNotAvailable,
  /**
   * User authentication failed.
   * @constant `4`
   */
  AuthFailed,
  /**
   * Biometrics have been locked out, usually because of too many failed attempts.
   *
   * @constant `5`
   */
  BiometricsLockedOut,
  /**
   * The operation failed because biometric authentication is not enabled.
   * This can occur when biometrics is not supported by the device
   * or when biometrics has not been configured for the device or vault.
   * @constant `6`
   */
  BiometricsNotEnabled,
}

/**
 * An error that can be thrown by the plugin.
 *
 * @example
 * try {
 *  vault.unlock();
 * } catch(e) {
 *   switch (e.code) {
 *     case VaultErrorCodes.AuthFailed:
 *       alert('You failed to authenticate!');
 *       break;
 *     case VaultErrorCodes.UserCanceledInteraction:
 *       alert('You cancelled the face id prompt!');
 *       break;
 *     default:
 *       throw e;
 *   }
 * }
 */
export interface VaultError {
  /**
   * - Type: `string`
   *
   * A text description of the error that occurred.
   */
  message: string;
  /**
   * - Type: {@link VaultErrorCodes}
   *
   * The error code enum representing the error.
   */
  code: VaultErrorCodes;
}

/**
 * An error that can be thrown in the Device class.
 *
 * @example
 * try {
 *  await Device.showBiometricPrompt(promptConfig);
 * } catch(e) {
 *   switch (e.code) {
 *     case DeviceErrorCodes.AuthFailed:
 *       alert('You failed to authenticate!');
 *       break;
 *     case DeviceErrorCodes.UserCanceledInteraction:
 *       alert('You cancelled the face id prompt!');
 *       break;
 *     default:
 *       throw e;
 *   }
 * }
 */
export interface DeviceError {
  /**
   * - Type: `string`
   *
   * A text description of the error that occurred.
   */
  message: string;
  /**
   * - Type: {@link DeviceErrorCodes}
   *
   * The error code enum representing the error.
   */
  code: DeviceErrorCodes;
}

/**
 * The possible values returned by {@link Device.getAvailableHardware}
 */
export enum SupportedBiometricType {
  /** The device supports fingerprint scanning. */
  Fingerprint = 'fingerprint',
  /** The device supports facial recognition. */
  Face = 'face',
  /** The device supports iris scanning. */
  Iris = 'iris',
}

/**
 * Possible device biometric strength levels on Android (always `strong` on iOS).
 * [More Information](https://source.android.com/security/biometric/measure#tiered-authentication).
 */
export enum BiometricSecurityStrength {
  /** Refers to Class 2 - Weak biometric security */
  Weak = 'weak',
  /** Refers to Class 3 - Strong biometric security */
  Strong = 'strong',
}

/**
 * Possible app biometric permissions states on iOS (always `granted` on Android and on iOS with TouchID).
 */
export enum BiometricPermissionState {
  /** App has been granted permission to use FaceID */
  Granted = 'granted',
  /** App has been denied permission to use FaceID */
  Denied = 'denied',
  /** User has not yet been prompted to allow FaceID */
  Prompt = 'prompt',
}

/**
 * When type is set to 'DeviceSecurity', determines which aspects of the device to use to secure the vault.
 * Note: Android only supports `SystemPasscode` on Android 11 and greater.
 * @default `Both`
 */
export enum DeviceSecurityType {
  /** Will allow a system passcode to secure the vault. On Android, only supported on Android 11 and greater. */
  SystemPasscode = 'SystemPasscode',
  /** WIll allow biometric hardware tos secure the vault. */
  Biometrics = 'Biometrics',
  /** WIll allow both SystemPasscode or Biometrics as an option to secure the vault. */
  Both = 'Both',
  /** No biometric security option will be used. */
  None = 'None',
}

/**
 * The type of vault
 */
export enum VaultType {
  /**
   * No additional security is required in the app.
   * This vault type cannot be locked/unlock so methods like `lock`, `unlock`, `isLocked`, and `lockAfterbackgrounded` have no effect.
   *
   * On iOS, this vault is synchronized via iCloud or new device setup.
   * */
  SecureStorage = 'SecureStorage',
  /**
   * Uses additional device features to add an additional layer of security while the user is in the app.
   *
   * On iOS, this vault is NOT synchronized via iCloud or new device setup.
   * */
  DeviceSecurity = 'DeviceSecurity',
  /**
   * User will set a custom passcode that will be used to access the vault.
   *
   * On iOS, this vault is NOT synchronized via iCloud or new device setup.
   * */
  CustomPasscode = 'CustomPasscode',
  /** Data will persist only while the application is in memory.
   * This vault type cannot be locked/unlock so methods like `lock`, `unlock`, `isLocked`, and `lockAfterbackgrounded` have no effect.
   *
   *  On iOS, this vault is NOT synchronized via iCloud or new device setup.
   */
  InMemory = 'InMemory',
}

/**
 * For Android, when type is {@link VaultType.DeviceSecurity} and deviceSecurityType is {@link DeviceSecurityType.Both}, this options specifies if you want to
 * prefer a Strong Cryptographic Vault or the System Passcode fallback when they both aren't available.
 * @default `StrongVault`
 */
export enum AndroidBiometricCryptoPreference {
  /** Prefer to use a strong cryptographic vault. */
  StrongVault = 'StrongVault',
  /** Prefer the system passcode fallback. */
  SystemPasscode = 'SystemPasscode',
}
